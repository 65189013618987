/*NoMatch*/
#nomatch {
  padding: 90px 0 40px 0;

  h1 {
    font-family: 'ProximaNova-Bold';
    font-size: 40px;
    color: #000000;
    letter-spacing: 0;
    line-height: 27px;
    padding: 0 15px;
  }

  h3 {
    padding: 0 15px;
  }
}

@media only screen and (max-width: 767px) {
  #nomatch {
    h1 {
      font-size: 32px;
      line-height: 35px;
    }
  }
}