@font-face {
  font-family: 'Proxima Nova Regular';
  src: url('../fonts/ProximaNova-Regular.eot');
  src: url('../fonts/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ProximaNova-Regular.woff') format('woff'),
  url('../fonts/ProximaNova-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'Proxima Nova Semibold';
  src: url('../fonts/ProximaNova-Semibold.eot');
  src: url('../fonts/ProximaNova-Semibold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ProximaNova-Semibold.woff') format('woff'),
  url('../fonts/ProximaNova-Semibold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'ProximaNova-Bold';
  src: url('../fonts/ProximaNova-Bold.eot');
  src: url('../fonts/ProximaNova-Bold.eot?#iefix') format('embedded-opentype'),
  url('../fonts/ProximaNova-Bold.woff') format('woff'),
  url('../fonts/ProximaNova-Bold.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}


body, html {min-height: 100%; padding: 0; margin: 0; font-family: 'Proxima Nova Regular';}
body {width: 100%; overflow-x: hidden;}
a {text-decoration: none;}
.clear {clear: both;}
* {outline:none;}
a img {border: none;}
ul {padding: 0; margin: 0;}

.container {
  margin: 0 auto;
  max-width: 1280px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.black-btn {
  padding: 10px 30px;
  font-family: 'ProximaNova-Bold';
  font-size: 18px;
  text-align: center;
  color: #000000;
  background: transparent;
  letter-spacing: 0;
  border: 4px solid #000000;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  cursor: pointer;
}

.black-btn:hover {
  color: #fff;
  background: #000;
}

.white-btn {
  padding: 10px 30px;
  font-family: 'ProximaNova-Bold';
  font-size: 18px;
  text-align: center;
  color: #fff;
  background: transparent;
  letter-spacing: 0;
  border: 4px solid #fff;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  cursor: pointer;
}

.white-btn:hover {
  color: #000;
  background: #fff;
}

.gray-btn {
  padding: 4px 28px;
  font-family: 'ProximaNova-Bold';
  font-size: 15px;
  text-align: center;
  color: #fff;
  border-radius: 4px;
  display: inline-block;
  letter-spacing: 0;
  border: 4px solid #BCBCBC;
  background: #BCBCBC;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  cursor: pointer;
}

.gray-btn:hover {
  border: 4px solid #000;
  background: #000;
}

b, strong {
  font-family: 'ProximaNova-Bold';
}

.colls-3 {
  float: left;
  width: 33.333%;
  padding: 0 15px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.colls-2 {
  float: left;
  width: 50%;
  padding: 0 15px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.colls-1 {
  padding: 0 15px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.tab {
  display: table;
  vertical-align: top;
  width: 100%;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.cell {
  display: table-cell;
  vertical-align: top;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

//Page changing animation
.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 250ms ease-in;
}