/*Tours*/
#tours {
  position: relative;

  .h1-wrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 2;
  }

  h1 {
    font-family: 'ProximaNova-Bold';
    font-size: 40px;
    color: #fff;
    letter-spacing: 0;
    line-height: 27px;
    padding: 0 15px;
  }

  .leftcol {
    padding-right: 10%;
  }

  .tour {
    position: relative;
    padding: 150px 0;
  }

  .tour-date {
    font-family: 'ProximaNova-Bold';
    font-size: 24px;
    color: #FFFFFF;
    letter-spacing: 1.49px;
    text-align: right;
  }

  .date-num {
    font-size: 48px;
    color: #FFFFFF;
    letter-spacing: 2.98px;
  }

  .tour-logo {
    img {
      max-width: 100%;
    }
  }

  .white-btn {
    margin-top: 80px;
    display: block;
    max-width: 213px;
  }

  .tour-icos {
    padding: 60px 0 0 0;

    img {
      display: inline-block;
      vertical-align: middle;
      padding: 20px 7% 20px 0;
      width: auto !important;
      height: auto !important;
    }
  }

  .tour-minidesc {
    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: 0;
    line-height: 26px;
  }

  .tour-desc {
    padding: 50px 0;
    position: relative;
    display: none;
  }

  .tour-desc-logo {
    float: left;
    height: 37px;
  }

  .tour-desc-dates {
    float: right;
    font-family: 'ProximaNova-Bold';
    font-size: 20px;
    color: #000000;
    letter-spacing: 0.83px;
    text-align: right;
    padding-top: 15px;
  }

  .tour-desc-link {
    display: block;
    margin: 20px 0;
    font-family: 'ProximaNova-Bold';
    font-size: 14px;
    color: #000000;
    letter-spacing: 0;
    padding: 0 0 0 20px;
    background: url(../img/link-ico.svg) no-repeat left center;

    &:hover {
      text-decoration: underline;
    }
  }

  .close-tour {
    position: absolute;
    top: 30px;
    right: 30px;
    cursor: pointer;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;

    &:hover {
      -moz-transform: scale(1.3);
      transform: scale(1.3);
    }
  }

  .slick-slider {
    margin-left: 40px;

    img {
      width: 100%;
    }
  }

  .slick-prev {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: auto;
    bottom: -80px;
    display: block;
    width: 19px;
    height: 38px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: url(../img/leftarr-black.svg) no-repeat center;
    background-size: 100% 100%;
    left: 30%;
    z-index: 99;
  }

  .slick-next {
    background: url(../img/rightarr-black.svg) no-repeat center;
    background-size: 100% 100%;
    width: 19px;
    height: 38px;
    right: 30%;
    top: auto;
    bottom: -80px;
    z-index: 99;
  }

  .slick-prev:before, .slick-next:before {
    display: none;
  }

  .slick-dots {
    bottom: -58px;
    z-index: 1;

    li {
      display: none;

      button:before {
        display: none;
      }

      &.slick-active {
        display: block;
        width: 100%;
        height: auto;
        padding: 0;
        margin: 0;
        text-align: center;
        font-family: 'ProximaNova-Bold';
        font-size: 16px;
        line-height: 30px;
        color: #000000;
        letter-spacing: 0;

        button {
          font-family: 'ProximaNova-Bold';
          font-size: 16px;
          line-height: 30px;
          color: #000000;
          letter-spacing: 0;
          width: auto;
          height: auto;
          padding: 0;
          margin: 0 auto;
        }


      }
    }
  }

  .form-head {
    font-family: 'ProximaNova-Bold';
    font-size: 24px;
    color: #000000;
    letter-spacing: 0;
    border-bottom: 4px solid #000;
    margin: 100px 0 15px 0;
    padding-bottom: 10px;
  }

  .cell1 {
    width: 700px;
  }
}

.tourFormInputWrap {
  display: inline-block;
  vertical-align: top;
  width: 50%;
  padding: 10px 10px 10px 0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.tourFormInputWrap input {
  background: #EBEBEB;
  padding: 16px 13px;
  font-size: 16px;
  color: #000000;
  letter-spacing: 0;
  border: 0;
  width: 100%;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.tourFormSubmit {
  padding: 10px 30px;
  font-family: 'ProximaNova-Bold';
  font-size: 18px;
  text-align: center;
  color: #000000;
  background: transparent;
  letter-spacing: 0;
  border: 4px solid #000000;
  margin: 10px 10px 10px 0;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
  cursor: pointer;
}

.tourFormSubmit:hover {
  color: #fff;
  background: #000;
}



@media only screen and (max-width: 850px) {
  .tour-desc .colls-2 {
    width: 100%;
  }

  #tours .tour-gal {
    margin-left: 0;
  }
}

@media only screen and (max-width: 767px) {
  #tours {
    h1 {
      font-size: 32px;
      line-height: 35px;
    }

    .colls-2 {
      padding: 15px;
      width: 100%;
    }

    .tour-date {
      font-size: 16px;
      letter-spacing: 0.49px;
    }

    .date-num {
      font-size: 28px;
      letter-spacing: 1.98px;
    }

    .tour-icos {
      padding: 20px 0 0 0;

      img {
        width: 30px !important;
      }
    }

    .white-btn {
      margin-top: 30px;
    }

    .slick-slider {
      margin-left: 0;
    }
  }
}


@media only screen and (max-width: 550px) {
  #tours {
    .tour-desc-logo {
      float: none;
      height: 37px;
    }

    .tour-desc-dates {
      float: none;
      text-align: left;
    }

    .tour-desc-link {
      display: block;
      margin: 20px 0 -50px 0;
    }

    .tab, .cell1, .cell2 {
      width: 100%;
      display: block;
    }
  }

  .tourFormInputWrap {
    width: 100%;
    display: block;
  }
}