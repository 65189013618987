/*Photos*/
#photos {
  padding: 30px 0 0 0;
  position: relative;

  .h1-wrapper {
    position: absolute;
    top: 30px;
    left: 0;
    width: 100%;
    text-align: center;
    z-index: 100;
  }

  .photos {
    div {
      height: 370px;
      /*overflow-y: hidden;*/
    }
  }

  img {
    height: 100%;
  }

  h1 {
    font-family: 'ProximaNova-Bold';
    font-size: 40px;
    color: #FFFFFF;
    letter-spacing: 0;
    line-height: 38px;
    text-align: left;
    max-width: 420px;
    padding: 0 15px;
  }

  .slick-prev {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 19px;
    height: 38px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: url(../img/leftarr.svg) no-repeat center;
    background-size: 100% 100%;
    left: 30px;
    z-index: 99;
  }

  .slick-next {
    background: url(../img/rightarr.svg) no-repeat center;
    background-size: 100% 100%;
    width: 19px;
    height: 38px;
    right: 30px;
  }

  .slick-prev:before, .slick-next:before {
    display: none;
  }
}

@media only screen and (max-width: 767px) {
  #photos h1 {
    font-size: 32px;
    line-height: 35px;
  }
}