/*First screen*/
#firstscreen {
  background: #EEEEEE;

  .container {
    position: relative;
    width: 100%;
    min-height: 680px;
  }

  .text-block {
    position: relative;
    max-width: 500px;
    font-size: 17px;
    color: #000000;
    letter-spacing: 0;
    line-height: 26px;
    padding: 200px 15px 0 15px;
  }

  .buts-block {
    margin: 80px 0 40px 0;
  }

  .black-btn {
    display: inline-block;
    vertical-align: middle;
  }

  .play-btn {
    vertical-align: middle;
    margin-left: 30px;
    cursor: pointer;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;
    display: none;

    &:hover {
      -moz-transform: scale(1.1);
      transform: scale(1.1);
    }
  }
}

.katja-main {
  width: 50%;
  max-width: 890px;
  position: absolute;
  right:7%;
  bottom: 0;
  z-index: 2;
}

.main-block2 {
  position: absolute;
  right:0;
  bottom: 0;
  z-index: 3;
}

.main-block3 {
  position: absolute;
  right:0;
  margin-right:40%;
  bottom: 0;
  z-index: 1;
}

.main-block4 {
  position: absolute;
  top: 90px;
  right:0;
  margin-right:50%;
  width: 20%;
  max-width: 261px;
}

@media only screen and (max-width: 1350px) {
  .main-block2 {
    max-width: 60%;
  }

  .main-block3 {
    max-width: 9%;
  }
}

@media only screen and (max-width: 980px) {
  #firstscreen {
    .text-block {
      padding: 100px 15px 0 15px;
    }

    .buts-block {
      margin: 40px 0 0 0;
    }
  }

  .main-block2 {
    display: none;
  }

  .main-block3 {
    display: none;
  }

  .main-block4 {
    display: none;
  }

  .katja-main {
    width: 80%;
    max-width: 890px;
    position: absolute;
    right: 0;
    bottom: 0;
    z-index: 2;
  }
}

@media only screen and (max-width: 550px) {
  .katja-main {
    width: 100%;
    max-width: 890px;
    position: relative;
    right: 0;
    bottom: 0;
    z-index: 2;
    float: right;
    margin-top: 20px;
  }

  #firstscreen .text-block {
    padding: 50px 15px 0 15px;
  }
}