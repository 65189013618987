/*Footer*/
footer {
  padding: 30px 0;
  background: #000;

  .botlogo {
    display: inline-block;
    vertical-align: middle;
    padding-right: 10px;
  }

  .botsoc {
    display: inline-block;
    vertical-align: middle;

    li {
      display: inline-block;
      vertical-align: middle;
      padding:0 10px;
    }
  }

  .botmail {
    margin: 15px 0;
    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: 0;
    line-height: 26px;
    display: block;

    &:hover {
      text-decoration: underline;
    }
  }

  .white-btn {
    display: block;
    max-width: 210px;
  }

  .copy {
    font-size: 13px;
    color: #7F7F7F;
    letter-spacing: 0;
    line-height: 26px;
    margin-top: 30px;
  }

  .botform-text {
    text-align: right;
    font-size: 16px;
    color: #FFFFFF;
    letter-spacing: 0;
  }

  .tab {
    margin: 15px 0 40px 0;
    float: right;
    max-width: 540px;

    .white-btn {
      border-width: 2px;
    }
  }

  .cell1 {
    padding-right: 15px;

    input {
      width: 100%;
      padding: 15px 15px 14px 15px;
      font-size: 14px;
      color: #282828;
      letter-spacing: 0;
      background: #EFEFEF;
      border: none;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }
  }

  .cell2 {
    width: 175px;
  }

  .chg {
    font-size: 13px;
    color: #7F7F7F;
    letter-spacing: 0;
    line-height: 26px;
    margin-top: 30px;
    text-align: right;

    a {
      color: #fff;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .submissionInput span {
    color: #fff;
    display: block;
    padding: 10px 0 0 0;
  }
}


@media only screen and (max-width: 650px) {
  footer {
    .colls-2 {
      width: 100%;
    }

    .botform-text {
      padding-top: 50px;
      text-align: left;
    }

    .chg {
      text-align: left;
    }

    .tab {
      float: none;
      margin: 15px 0 0 0;
    }
  }
}

@media only screen and (max-width: 550px) {
  footer {
    .tab, .cell {
      display: block;
      width: 100%;
      padding: 10px 0;
    }
  }
}