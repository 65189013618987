/*Navigation*/
nav {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 9000;
  padding: 10px 0;

  &.fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9000;
    padding: 10px 0;
    background: #fff;
    border-bottom: 1px solid #EEEEEE;

    .logo, .innerlogo {
      height: 38px;
    }

    .menu, .innermenu {
      padding: 8px 0;
    }

    .top-icons, .innertop-icons {
      padding: 8px 0 8px 30px;
    }
  }

  .container {
    padding: 0 15px;
  }
}

.logo {
  float: left;
}

.innerlogo {
  height: 38px;
}

.menu {
  float: right;
  padding: 25px 0;
  margin: 0;

  li {
    display: inline-block;
    vertical-align: middle;
    padding: 0 15px;
    list-style: none;

    a {
      font-family: 'ProximaNova-Bold';
      font-size: 13px;
      border-bottom: 1px solid transparent;
      color: #000000;
      letter-spacing: 1.5px;
      text-transform: uppercase;
      cursor: pointer;
      display: inline-block;
      -webkit-transition: all 0.5s;
      -moz-transition: all 0.5s;
      -o-transition: all 0.5s;
      transition: all 0.5s;
    }
  }

}

.innermenu {
  padding: 8px 0;
}

.menu li a:hover, .menu a.active {
  border-bottom: 1px solid #000;
}

.top-icons {
  float: right;
  padding: 25px 0 25px 30px;
  margin: 0;

  li {
    display: inline-block;
    vertical-align: middle;
    padding: 0 10px;
    list-style: none;

    img {
      -webkit-transition: all 0.2s;
      -moz-transition: all 0.2s;
      -o-transition: all 0.2s;
      transition: all 0.2s;

      &:hover {
        -moz-transform: scale(1.3);
        transform: scale(1.3);
      }
    }
  }
}

.innertop-icons {
  padding: 8px 0 8px 30px;
}

@media only screen and (max-width: 1050px) {
  .logo {
    width: 180px;
    padding-top: 10px;
  }

  nav.fixed .logo, nav.fixed .innerlogo, .innerlogo {
    padding-top: 0;
  }

  .menu li {
    padding: 0 8px;

    a {
      font-size: 12px;
      letter-spacing: 0.5px;
    }
  }
}

@media only screen and (max-width: 850px) {
  nav.fixed {
    .logo, .innerlogo {
      padding-top: 0;
    }

    .top-icons, .innertop-icons {
      padding: 8px 0 8px 15px;
    }
  }

  .logo {
    width: 120px;
    padding-top: 17px;
  }

  .innerlogo {
    padding-top: 0;
  }

  .top-icons {
    padding: 25px 0 25px 15px;
  }

  .innertop-icons {
    padding: 8px 0 8px 15px;
  }

}

@media only screen and (max-width: 767px) {
  nav, nav.fixed {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 9000;
    padding: 5px 0;
    height: 40px;
    background: #fff;
    border-bottom: 1px solid #EEEEEE;

    .menu {
      position: fixed;
      top: 0;
      right: -500px;
      z-index: 9000;
      width: 240px;
      height: 100%;
      background: #fff;
      padding: 60px 20px 20px 20px;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
      -webkit-transition: all 0.5s;
      -moz-transition: all 0.5s;
      -o-transition: all 0.5s;
      transition: all 0.5s;

      &.opened {
        right: 0;
      }

      li {
        display: block;
        padding: 10px 0;
      }
    }

    .logo {
      padding-top: 3px;
    }

    .top-icons {
      padding: 10px 120px 10px 0;
      position: absolute;
      z-index: 9001;
      top: 10px;
      right: -500px;
      -webkit-transition: all 0.5s;
      -moz-transition: all 0.5s;
      -o-transition: all 0.5s;
      transition: all 0.5s;

      &.opened {
        right: 0;
      }
    }
  }

  .hamburger {
    display: block;
    position: absolute;
    right: 15px;
    top: 15px;
    width: 30px;
    height: 20px;
    z-index: 9001;
    cursor: pointer;
  }

  .hamburger__inner {
    display: block;
    width: 30px;
    height: 3px;
    margin-top: 10px;
    background: #000;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  .hamburger__inner::before, .hamburger__inner::after {
    display: block;
    position: absolute;
    content: "";
    z-index: -1;
    width: 30px;
    height: 3px;
    background: #000;
    -webkit-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
  }
  .hamburger__inner::before {
    top: 0;
  }
  .hamburger__inner::after {
    top: 20px;
  }

  .hamburger--open .hamburger__inner {
    background: none;
  }
  .hamburger--open .hamburger__inner::before, .hamburger--open .hamburger__inner::after {
    top: 12px;
  }
  .hamburger--open .hamburger__inner::before {
    -webkit-transform: rotate(-45deg);
    -ms-transform: rotate(-45deg);
    transform: rotate(-45deg);
  }
  .hamburger--open .hamburger__inner::after {
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
}