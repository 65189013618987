/*About me*/
#aboutme {
  padding: 40px 0 0 0;
  margin-top: -30px;
  font-size: 16px;
  color: #000000;
  letter-spacing: 0;
  line-height: 24px;

  .leftblock {
    float: left;
    width: 55%;
    padding: 0 40px 0 15px;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .rightblock {
    float: left;
    width: 45%;
    -moz-box-sizing: border-box;
    box-sizing: border-box;

    .colls-3 {
      padding: 30px;
      text-align: center;
      font-family: 'ProximaNova-Bold';
      font-size: 15px;
      color: #000000;
      letter-spacing: 0;
      line-height: 19px;
      min-height: 215px;

      a {
        color: #000000;

        &:hover {
          text-decoration: underline;
        }
      }

      img {
        display: block;
        margin: 0 auto 10px auto;
      }
    }
  }

  h1 {
    font-family: 'ProximaNova-Bold';
    font-size: 40px;
    color: #000000;
    letter-spacing: 0;
    line-height: 27px;
    padding: 0 15px;
    margin-top: 0;
  }

  .about-ava {
    float: left;
    padding: 5px 15px 5px 0;
    width: 90px;
  }

}

.ico1 {
  padding: 2px 0;
}

.ico3 {
  padding: 8px 0;
}

.ico4 {
  padding: 4px 0;
}

.ico5 {
  padding: 7px 0;
}

.ico6 {
  position: relative;
  width: 66px;
  height: 66px;
  margin: 0 auto 10px auto;
}

.ico6-outer {
  position: relative;
  animation-name: rote;
  animation-duration: 10s;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.ico6-inner {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -11px 0 0 -8px !important;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  -o-transition: all 0.5s;
  transition: all 0.5s;
}

.rightblock .colls-3 a:hover .ico6-inner {
  -moz-transform: scale(1.2);
  transform: scale(1.2);
}

@keyframes rote {
  from {
    transform:rotate(0deg);
  }
  to {
    transform:rotate(360deg);
  }
}

.partners {
  h4 {
    font-family: 'ProximaNova-Bold';
    font-size: 20px;
    color: #000000;
    letter-spacing: 0;
    line-height: 27px;
    border-bottom: 2px solid #000;
  }

  img {
    display: inline-block;
    vertical-align: middle;
    padding: 10px 2%;
    max-width: 8%;
    max-height: 68px;
    height: auto !important;
    width: auto !important;
  }
}

.logo-ikea {
  width: 90px;
}

.logo-boconcept {
  width: 107px;
}

.logo-ey {
  width: 40px;
}

.logo-finnair {
  width: 105px;
}

.logo-ids {
  width: 51px;
}

.logo-sclub {
  width: 80px;
}

.logo-lumas {
  width: 100px;
}

.logo-ciferblat {
  width: 48px;
}

.logo-greenhouse {
  width: 60px;
}

.text-center {
  text-align: center;
}

@-moz-document url-prefix() {
  #aboutme {
    margin-top: 0px;
  }
}

@media only screen and (max-width: 1350px) {
  .partners img {
    padding: 10px 2.5%;
    max-width: 6%;
  }
}

@media only screen and (max-width: 980px) {
  #aboutme {
    margin: 0;

    .leftblock {
      float: none;
      display: block;
      width: 100%;
      padding: 20px 15px;
    }

    .rightblock {
      float: none;
      display: block;
      width: 100%;
      padding: 20px 15px;
    }
  }

  .partners img {
    padding: 10px 1.5%;
    max-width: 6%;
  }
}

@media only screen and (max-width: 767px) {
  #aboutme h1 {
    font-size: 32px;
    line-height: 35px;
    margin-bottom: 0;
  }
}

@media only screen and (max-width: 550px) {
  .rightblock .colls-3 {
    width: 50%;
    padding: 15px;
  }

  .ico4 {
    padding: 11px 0;
  }

  .partners img {
    padding: 15px 20px;
    max-width: 20%;
  }
}