/*Events*/
#events {
  padding: 20px 0 40px 0;

  h1 {
    font-family: 'ProximaNova-Bold';
    font-size: 40px;
    color: #000000;
    letter-spacing: 0;
    line-height: 27px;
    padding: 0 15px;
    float: left;
  }

  .colls-2 {
    padding: 20px 15px;
  }

  .past-events {
    float: right;
    font-family: 'ProximaNova-Bold';
    font-size: 16px;
    color: #979797;
    letter-spacing: 0;
    text-align: right;
    margin: 37px 15px 0 0;
    cursor: pointer;
    border-bottom: 1px solid transparent;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
    -o-transition: all 0.5s;
    transition: all 0.5s;

    &:hover {
      border-bottom: 1px solid #979797;
    }
  }

  .event-header {
    border-bottom: 4px solid #000000;
    margin-bottom: 15px;
  }

  .event-date {
    font-family: 'ProximaNova-Bold';
    font-size: 24px;
    color: #000000;
    letter-spacing: 1.49px;
    float: left;
  }

  .date-num {
    font-size: 48px;
    color: #000000;
    letter-spacing: 2.98px;
  }

  .event-place {
    float: right;
    font-family: 'ProximaNova-Bold';
    font-size: 18px;
    color: #000000;
    letter-spacing: 1.12px;
    text-align: right;
    padding-top: 27px;
  }

  .event-img {
    width: 100%;
    height: 230px;
    overflow: hidden;

    img {
      height: 100%;
      min-width: 100%;
    }
  }

  .event-title {
    font-family: 'ProximaNova-Bold';
    font-size: 28px;
    color: #000000;
    letter-spacing: 0;
    margin: 15px 0;
  }

  .event-desc {
    font-size: 16px;
    color: #000000;
    letter-spacing: 0;
    line-height: 26px;
  }

  .event-address {
    float: left;
    font-family: 'ProximaNova-Bold';
    font-size: 16px;
    color: #000000;
    letter-spacing: 0;
    line-height: 26px;
    padding-right: 10px;
  }

  .event-time {
    float: right;
    font-family: 'ProximaNova-Bold';
    font-size: 16px;
    color: #000000;
    letter-spacing: 0;
    line-height: 26px;
  }

  &.pastevents {
    margin-top: 30px;

    h3 {
      padding: 0 15px;
    }
  }

  #yearSort {
    display: inline-block;
    vertical-align: top;
    width: 55px;
    height: 27px;
    text-align: left;
    margin: 0 0 0 15px;
    padding: 0;
    font-family: 'ProximaNova-Bold';
    font-size: 18px;
    color: #A7A7A7;
    letter-spacing: 0;
    cursor: pointer;
    border-bottom: 2px solid transparent;
    -webkit-transition: all 0.2s;
    -moz-transition: all 0.2s;
    -o-transition: all 0.2s;
    transition: all 0.2s;

    &:hover, &.active {
      font-size: 24px;
      color: #000000;
      letter-spacing: 0;
      margin: -4px 0 0 15px;
      padding: 0 0 4px 0;
      border-bottom: 2px solid #000;
    }
  }
}

@media only screen and (max-width: 980px) {
  #events .colls-2 {
    width: 100%;
    padding: 20px 15px;
  }
}

@media only screen and (max-width: 767px) {
  /*Events*/
  #events {
    h1 {
      font-size: 32px;
      line-height: 35px;
    }

    .event-date {
      font-size: 18px;
      letter-spacing: 0.49px;
    }

    .date-num {
      font-size: 28px;
      letter-spacing: 1.98px;
    }

    .event-place {
      float: right;
      font-size: 16px;
      letter-spacing: 0.12px;
      padding-top: 12px;
    }

    .event-title {
      font-size: 21px;
      margin: 5px 0;
    }

    .event-address {
      font-size: 13px;
      line-height: 16px;
      float: none;
      display: block;
    }

    .event-time {
      font-size: 13px;
      line-height: 16px;
      float: none;
      display: block;
      padding-top: 10px;
    }
  }

}

@media only screen and (max-width: 550px) {
#events {
  .past-events {
    font-size: 13px;
    margin: 40px 15px 0 0;
  }
}
}