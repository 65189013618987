/*Interview*/
#interview {
  padding: 20px 0;
  background: #F2F2F2;

  h1 {
    font-family: 'ProximaNova-Bold';
    font-size: 40px;
    color: #000000;
    letter-spacing: 0;
    line-height: 27px;
    padding: 0 15px;
  }

  .slick-prev {
    font-size: 0;
    line-height: 0;
    position: absolute;
    top: 50%;
    display: block;
    width: 19px;
    height: 38px;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
    cursor: pointer;
    color: transparent;
    border: none;
    outline: none;
    background: url(../img/leftarr-black.svg) no-repeat center;
    background-size: 100% 100%;
    left: -30px;
    z-index: 99;
  }

  .slick-next {
    background: url(../img/rightarr-black.svg) no-repeat center;
    background-size: 100% 100%;
    width: 19px;
    height: 38px;
    right: -30px;
  }

  .slick-prev:before, .slick-next:before {
    display: none;
  }

  .leftblock {
    float: left;
    width: 70%;
    padding: 40px 40px 40px 15px;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .rightblock {
    float: right;
    width: 25%;
    padding: 40px 10px 40px 10px;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
  }

  .cell1 {
    padding-right: 20px;

    iframe {
      width: 100% !important;
    }
  }

  .cell2 {
    width: 290px;
  }

  .text1 {
    font-family: 'ProximaNova-Bold';
    font-size: 18px;
    color: #000000;
    letter-spacing: 0;
    margin: 15px 0;
  }

  .cell3 {
    padding-right: 10px;
  }

  .cell4 {
    font-family: 'ProximaNova-Bold';
    font-size: 17px;
    color: #000000;
    letter-spacing: 0;

    p {
      padding: 0;
      margin-top: 0;
    }
  }

  .black-btn {
    max-width: 290px;
    display: block;
    margin-top: 60px;
  }
}

.int-block-wrap {
  padding: 0 15px;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.int-block {
  .int-img {
    width: 100%;
    height: 188px;
    overflow: hidden;

    img {
      height: 100%;
      min-width: 100%;
    }
  }

  .int-text {
    background: #fff;
    padding: 20px;
    font-family: 'ProximaNova-Bold';
    font-size: 17px;
    color: #000000;
    letter-spacing: 0;
    min-height: 120px;

    img {
      max-width: 90%;
      max-height: 90%;
      height: auto!important;
      width: auto!important;
    }
  }

  .int-logo {
    height: 32px;
    padding-bottom: 10px;
  }
}

.logo-houzz {
  width: 87px;
}

.logo-allworkshops {
  width: 125px;
}

.logo-afisha {
  width: 150px;
}

.logo-mir {
  width: 64px;
}

.logo-nn {
  width: 120px;
}

.logo-ort {
  width: 37px;
}

.katja-ava {
  width: 90px;
}

@media only screen and (max-width: 1350px) {
  #interview {
    .slick-prev {
      left: 30px;
    }

    .slick-next {
      right: 30px;
    }
  }
}

@media only screen and (max-width: 1150px) {
  #interview {
    .leftblock {
      float: left;
      width: 100%;
      padding: 40px 15px 40px 15px;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }

    .rightblock {
      float: left;
      width: 100%;
      padding: 0 15px 40px 15px;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
    }

    .cell3 {
      width: 110px;
    }
  }
}

@media only screen and (max-width: 767px) {
  #interview {
    h1 {
      font-size: 26px;
      line-height: 31px;
    }

    .table, .cell1, .cell2 {
      display: block;
      width: 100%;
      padding: 15px 0;
    }

    .rightblock {
      padding: 0 15px 20px 15px;
    }
  }
}